.home{

}
.info-box{
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    min-width: 300px;
    max-width: 500px;
    width: 40vw;
    height: 400px;
    background: #fff;
    margin: -80vh auto;
    position:relative;
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    z-index:555;
    border-radius: 10px;
}
.info-box h1{
    font-family: 'Poiret One', cursive;
    font-size:2.2em;
}
.info-box h4{  
    font-family: "JetBrains Mono",monospace;
    font-size:1.1em;
    margin-top:-40px;
}
.info-box img{
    margin: 0 auto;
    margin-top: -125px;
    height: 200px;
    width: 200px;
    border-radius: 100px;
    box-shadow: 
        2px 3px 5px rgba(0, 0, 0, 0.7);
}
.info-btns{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.sky-container {
  width: 100%;
  height:100vh;
  overflow: hidden;
  background: linear-gradient(
    to top, 
    #3b3b3b 5%,
    #3b3b3b 15%,
    #415eff 70%,
    #415eff 90%
  );
}
#sky {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  margin: 0;
  padding: 0;
  left: 0;
}
.sky{
    overflow: hidden;
    height: 100vh;
}

#shootingstars {
  margin: 0;
  padding: 0;
  width: 150vh;
  height: 100vw;
  position: fixed;
  overflow: hidden;
  transform: rotate(0deg);
}

.wish {
  height: 2px;
  top: 300px;
  width: 100px;
  margin: 0;
  opacity: 0;
  padding: 0;
  background-color: white;
  position: absolute;
  background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
  filter: drop-shadow(0 0 6px white);
  overflow: hidden;
}
