.header{
    padding-top: 20px;
    z-index: 2;
    height:6em;
    width:100%;
    display: flex;
    justify-content: center;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0.982) 61%, rgb(214, 221, 255) 80%, #8894ff 100%);
}
* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0px;
  }
  
  .nav {
    padding-left: 10px;
    height: 80px;
    width: 100%;
    max-width: 1300px;
    position: relative;
  }
  
  .nav > .nav-header {
    display: inline;
  }
  .nav-header > img {
      display: none;
      height: 80px;
  }
  
  .nav > .nav-header > .nav-logo {
    font-family: 'Poiret One', cursive;
    display: inline-block;
    height: 100%;
    width: auto;


  }

  
  .nav > .nav-btn {
    display: none;
  }
  
  .nav > .nav-links {
    display: flex;
    height: 100%;
    align-items: center;
    float: right;
    font-size: 18px;
  }
  

  
  .active{
    box-shadow: 1px 1px 2px 0px #000000 inset, -1px -1px 2px 1px rgba(0, 0, 0, 0.5) inset !important;
    background-image: radial-gradient(1% 50% at 100% 0, #5adaff 0, #5468ff 100%) !important;
  }
  .active:hover{
    transform: scale(.99) !important;
    cursor: default !important;
  }
  .nav > .nav-links > a {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono",monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding: 0 26px;
    margin: 0 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
  }

  
  .nav > .nav-links > a:hover {
    background-color: rgba(255, 255, 255, 0.5);
    transform: scale(1.2)
  }
  
  .nav > #nav-check {
    display: none;
  }
  
  @media (max-width:875px) {
    .nav > .nav-btn {
      padding-top: 15px;
      display: inline-block;
      position: absolute;
      right: 5px;
      top: 0px;
    }
    .nav > .nav-btn > label {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding: 13px;
    }
    .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
      background-color: #00000034;
      border-radius: 40px;

    }
    
    #nav-check:checked ~ .nav-btn label span:nth-child(1) {
      position:absolute;
      transform: rotate(90deg); 
      right: 15px;
      top: 35px;
    }
    #nav-check:checked ~ .nav-btn label span:nth-child(2) {
      position:absolute;
      transform: rotate(60deg);
      top:46px;
      width: 10px;
      left: 15px;

    }
    #nav-check:checked ~ .nav-btn label span:nth-child(3) {
      position:absolute;
      top: 42px;
      left:21px;
      transform: rotate(120deg); 
      width: 10px;
    }
    .nav > .nav-btn > label > span {
      display: block;
      width: 25px;
      height: 10px;
      border-top: 2px solid #8283eb;
      transition: transform .4s;
    }
    .nav > .nav-links {
      position: absolute;
      z-index: 99999;
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1px solid #8283eb;
      backdrop-filter: blur(20px); 
      transition: all 0.8s ease-in;
      overflow-y: scroll;
      top: 80px;
      left: 0px;
    }
    .nav > .nav-links > a {
      background-color: rgba(255, 255, 255, 0.5);
      font-size: 38px;
      display: block;
      margin-top: 20px;
      margin-left: 0px;
      margin-right: 0px;
      width: 90%;
      align-items: center;
    }
    .nav > #nav-check:not(:checked) ~ .nav-links {
      height: 0px;
    }
    .nav > #nav-check:checked ~ .nav-links {
      height: calc(100vh - 100px);
      overflow-y: auto;
    }
  }